import { LotStatus } from "api/models/lotStatus";
import { LotSummary } from "api/models/lotSummary";
import { Page } from "api/models/page";
import { GetLotsQuery } from "api/requests/getLotsQuery";
import { useLotsService } from "api/services/lotsService";
import Drawer from "components/drawer";
import LotsFilters from "components/filter/LotsFilters";
import LotsListView from "components/lot/LotsListView";
import Spinner from "components/spinner";
import { Button, Pagination, Sidebar } from "flowbite-react";
import useLotsSearchParams from "hooks/lotsSearchParams";
import { useEffect, useState } from "react";
import { HiChevronDoubleRight } from "react-icons/hi";
import { Link, useLocation, useParams } from "react-router-dom";
import { ViewBreakpoints } from "static-data/constants";
import { useAppSelector } from "store";
import { useSlug } from "utils/slugHelper";

const LotsDashboardPage = () => {
  const { getLots } = useLotsService();
  const location = useLocation();
  const { categorySlug } = useParams();
  const { slugify, getSlugHash } = useSlug();
  const categoryId: number = getSlugHash(categorySlug);
  const [isLoadingLots, setIsLoadingLots] = useState(true);
  const categories = useAppSelector((state) => state.staticData.categories);
  const countries = useAppSelector((state) => state.staticData.countries);
  const [lots, setLots] = useState<Page<LotSummary>>(null);
  const [totalPages, setTotalPages] = useState(1);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showCategoriesDrawer, setShowCategoriesDrawer] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const { params: lotsSearchParams, setPage, queryString } = useLotsSearchParams();
  const { search, country: selectedCountries, status: selectedStatuses, isReserved, page, sortBy, sortDirection } = lotsSearchParams;

  const allowedLotStatuses: LotStatus[] = ["Inactive", "Active"];
  const defaultSortBy = sortBy ?? "EndsAtUtc";

  const activeRoute = (routeName: string) => {
    return decodeURI(location.pathname) === routeName;
  };

  const fetchLots = async () => {
    setIsLoadingLots(true);
    const lotsQuery: GetLotsQuery = {
      categoryIds: categoryId ? [categoryId] : undefined,
      countryCodes: selectedCountries,
      statuses: selectedStatuses.length > 0 ? selectedStatuses : ['Active', 'Inactive'],
      isReserved: (isReserved === undefined || isReserved === null || isNaN(isReserved)) ? null : !!isReserved,
      text: search,
      includeAuction: true,
      includeLastBid: true,
      pageIndex: page ? page - 1 : 0,
      pageSize: 10,
      sortBy: defaultSortBy,
      sortDescending: sortDirection === "desc"
    };

    const lotsData = (await getLots(lotsQuery));
    setLots(lotsData.data);
    setTotalPages(Math.trunc((lotsData.data.totalCount + lotsQuery.pageSize - 1) / lotsQuery.pageSize));
    setIsLoadingLots(false);
  };

  useEffect(() => {
    const handleResize = (() => window.innerWidth < ViewBreakpoints.xl ? setIsSmallScreen(true) : setIsSmallScreen(false));
    handleResize();
    window.addEventListener("resize", handleResize);

    fetchLots();

    return () => {
      window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, queryString]);

  const categoriesSidebar =
    <div className="border-gray-200 border-2 rounded-lg">
      <Sidebar aria-label="Categorii" className="w-full">
        <Sidebar.Items className={isSmallScreen ? "text-center" : ""}>
          <Sidebar.ItemGroup>
            <p className="text-center font-bold text-lg">Categorii</p>
          </Sidebar.ItemGroup>
          <Sidebar.ItemGroup>
            {categories && categories.map((category) => (
              <Sidebar.Item key={category.id}
                onClick={() => setSelectedCategory(category.name)}
                as={Link}
                to={`/lots/${slugify(category.name, category.id)}?${queryString}`}
                active={activeRoute(`/lots/${slugify(category.name, category.id)}`)}>
                {category.name}
              </Sidebar.Item>
            ))}
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>;

  return (
    <div className="w-full h-full flex flex-col xl:flex-row gap-4">

      <Drawer isOpen={showCategoriesDrawer && isSmallScreen} onClose={() => setShowCategoriesDrawer(false)}>
        {categoriesSidebar}
      </Drawer>

      {!isSmallScreen && categoriesSidebar}

      <div className="bg-lightPrimary w-full min-w-0 flex-col mx-auto space-y-2">
        {selectedCategory &&
          <h3 className="mt-2 ml-2 md:ml-4 font-semibold text-xl md:text-2xl xl:text-3xl">{selectedCategory}</h3>}
        {isSmallScreen &&
          <Button size="sm" outline color="light"
            onClick={() => setShowCategoriesDrawer(true)}
            className="text-white rounded-lg w-full">
            <HiChevronDoubleRight className="mr-2 h-5 w-5" />Categorii
          </Button>
        }

        <LotsFilters withCountries={countries} withStatuses={allowedLotStatuses} withIsReserved={true} />

        {isLoadingLots
          ? <Spinner />
          : <>
            <div className="space-y-2">
              {lots && <LotsListView lotSummaries={lots.items} />}
              {(!lots || lots.items.length === 0) && <p className="text-center text-gray-500">Nu a fost găsit niciun lot</p>}
            </div>

            {lots && totalPages > 1 && <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={(page) => setPage(page)}
              showIcons
              nextLabel="Înainte"
              previousLabel="Înapoi"
              className="flex justify-center md:float-right" />}
          </>}
      </div>
    </div>
  );
};

export default LotsDashboardPage;
