import { Page } from "api/models/page";
import { User } from "api/models/user";
import { GetUsersQuery } from "api/requests/getUsersQuery";
import { UpdateUserStatusCommand } from "api/requests/updateUserStatusCommand";
import { useUserService } from "api/services/usersService";
import PendingUsersTable from "components/admin/PendingUsersTable";
import Spinner from "components/spinner";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";

const requestQuery: GetUsersQuery = {
  status: "ValidationInProgress",
  sortDescending: false,
  sortBy: "LastOnboardingSubmittedAtUtc",
  pageSize: 10,
  pageIndex: 0
};

const PendingUsersPage = () => {
  const { getUsers, setUserStatus } = useUserService();
  const { successToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pendingUsers, setPendingUsers] = useState<Page<User>>(null);

  const fetchData = async () => {
    const data = (await getUsers(requestQuery)).data;

    setCurrentPage(requestQuery.pageIndex);
    setTotalPages(Math.trunc((data.totalCount + requestQuery.pageSize - 1) / requestQuery.pageSize));
    setPendingUsers(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = async (page: number) => {
    requestQuery.pageIndex = page - 1;

    await fetchData();
  }

  const onApproveUser = async (user: User) => {
    setIsLoading(true);
    const command: UpdateUserStatusCommand = {
      id: user.id,
      status: "Approved"
    };
    await setUserStatus(user.id, command);
    await fetchData();
    setIsLoading(false);

    successToast(`Contul firmei ${user.company?.name} a fost aprobat cu succes!`)
  };

  const onRejectUser = async (user: User) => {
    setIsLoading(true);
    const command: UpdateUserStatusCommand = {
      id: user.id,
      status: "Rejected"
    };
    await setUserStatus(user.id, command);
    await fetchData();
    setIsLoading(false);

    successToast(`Contul firmei ${user.company?.name} a fost refuzat cu succes!`);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <h5 className="py-2 font-bold text-navy-700 text-lg md:text-2xl ">Cereri de înregistrare</h5>
      <div className="mt-4 grid h-full w-full grid-cols-1">
        <PendingUsersTable
          users={pendingUsers.items}
          currentPage={currentPage + 1} totalPages={totalPages}
          onPageChange={onPageChange}
          onApproveUser={onApproveUser}
          onRejectUser={onRejectUser}
        />
      </div>
    </>
  );
}

export default PendingUsersPage;
